import React from 'react';
import Helmet from 'react-helmet';
import FacebookPixel from '../components/blocks/FacebookPixel';
import GoogleAnalytics from '../components/blocks/GoogleAnalytics';
import './index.css';
import './fonts.css';

const TITLE = 'Relate | en svensk tankeledare inom romantiska relationer';
const DESCRIPTION = 'Skapa meningsfulla och hållbara relationer som förgyller livet. Träffa en livspartner genom dina värderingar, eller fördjupa din befintliga relation med någon av våra appar.';

const APP_NAME = process.env.GATSBY_APP_NAME;
const ROOT_URL = process.env.GATSBY_ROOT_URL;
const IOS_APP_ID = process.env.GATSBY_DATE_IOS_APP_ID;

const Layout = ({ children }) => (
  <div>
    <Helmet
      title={TITLE}
      htmlAttributes={{
        lang: 'sv',
      }}
      meta={[
        { name: 'description', content: `${DESCRIPTION}` },
        { property: 'og:description', content: `${DESCRIPTION}` },
        { name: 'keywords', content: 'relate, app, dejting, dating, valuesmatch, personal values, online dating, matchmaking, Stockholm' },
        { name: 'apple-itunes-app', content: `app-id=${IOS_APP_ID}` },
        { name: 'application-name', content: `${APP_NAME}` },
        { property: 'og:site_name', content: `${APP_NAME}` },
        { property: 'og:url', content: `${ROOT_URL}` },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: `${ROOT_URL}/relate.png` },
        { name: 'twitter:image:src', content: `${ROOT_URL}/relate.png` },
        { name: 'twitter:card', content: `summary_large_image` },
        { name: 'google-site-verification', content: 'DhTXqMzSZttxSQeqD53ck9LZ2RX7mGH_ddCLY5A1TEg' },
        { name: 'facebook-domain-verification', content: 'zrj995kqhofja636ejzins5gbnow1b' },
        { property: 'fb:app_id', content: '976402652522989' },
        { name: 'twitter:site', content: '@RelateOnValues' },
      ]}
    >
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#8e603c" />
      <meta name="msapplication-TileColor" content="#8e603c" />
      <meta name="theme-color" content="#8E603C" />
    </Helmet>
    {children}
    <FacebookPixel />
    <GoogleAnalytics />
  </div>
);

export default Layout;
