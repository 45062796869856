module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"domain":"relate-admin-prod.imgix.net","secureURLToken":"5M5jY8THSjU4RgP4","sourceType":"webFolder","defaultImgixParams":{"auto":"format,compress"},"fields":[{"nodeType":"StrapiUploadFile","rawURLKey":"url","fieldName":"imgixImage"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
