/* global fbq ga */

exports.onRouteUpdate = function() {
  sessionStorage.clear();

  // Don't track while developing.
  if (process.env.GATSBY_KEY_ENV === 'production' && typeof fbq === 'function') {
    fbq('track', 'ViewContent');
  }
  // Don't track while developing.
  if (process.env.GATSBY_KEY_ENV === 'production' && typeof ga === 'function') {
    if (
      window.location &&
      typeof window.excludeGAPaths !== 'undefined' &&
      window.excludeGAPaths.some(rx => rx.test(window.location.pathname))
    ) {
      return;
    }
    window.ga(
      'set',
      'page',
      window.location ? window.location.pathname.replace(/^\/-\//, '/') + window.location.search + window.location.hash : undefined
    );
    window.ga('send', 'pageview');
  }
};
