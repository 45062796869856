/* global fbq */
import React from 'react';

const NODE_ENV = process.env.NODE_ENV;
const FACEBOOK_PIXEL = process.env.GATSBY_FACEBOOK_PIXEL;

class FacebookPixel extends React.PureComponent {
  componentDidMount() {
    if (NODE_ENV === 'production') {
      this.timeout = setTimeout(this.load, 3500);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  load() {
    (function(f, b, e, v, n, t, s){
      if(f.fbq) return;
      n = f.fbq = function(){
        n.callMethod ?
          n.callMethod.apply(n,arguments) : n.queue.push(arguments);
      }; if (!f._fbq) f._fbq = n; // eslint-disable-line
      n.push = n; n.loaded =! 0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async =! 0;
      t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s);
    })(window, document,'script','https://connect.facebook.net/en_US/fbevents.js', undefined, undefined, undefined);

    fbq('init', FACEBOOK_PIXEL); // Insert your pixel ID here.
    fbq('track', 'PageView');
  }

  render() {
    return null;
  }
}

export default FacebookPixel;
