/* global ga */
import React, { useEffect } from 'react';

const NODE_ENV = process.env.NODE_ENV;
const GOOGLE_ANALYTICS_TRACKING_ID = process.env.GATSBY_GOOGLE_ANALYTICS_TRACKING_ID;

const GoogleAnalytics = () => {
  useEffect(() => {
    let timeout;
    if (NODE_ENV === 'production') {
      /** init gtm after 3500 seconds - this could be adjusted */
      timeout = setTimeout(() => {
        if (window.gtmDidInit) {
          return false;
        }
        window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
    
        (function(i, s, o, g, r, a, m){
          i['GoogleAnalyticsObject'] = r; i[r] = i[r] || function() {
            (i[r].q = i[r].q || []).push(arguments);
          };
          i[r].l = 1 * new Date();
          a = s.createElement(o);
          m = s.getElementsByTagName(o)[0]; a.async = 1; a.src = g; m.parentNode.insertBefore(a,m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga', undefined, undefined);
    
        if (typeof ga === 'function') {
          ga('create', GOOGLE_ANALYTICS_TRACKING_ID, 'auto');
          ga('set', 'anonymizeIp', true);
          ga('set', 'page', window.location ? window.location.pathname.replace(/^\/-\//, '/') + window.location.search + window.location.hash : undefined);
          ga('send', 'pageview');
        }
      }, 3500);
    }
    return () => clearTimeout(timeout);
  }, []);

  return <div>hello</div>;
}

export default GoogleAnalytics;
